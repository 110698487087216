import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {


userdata:any;
transactionData:any;
flow:boolean=false;

  constructor() { }
}
