<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fad418" type="ball-atom" [fullScreen]="true">
    <p style="color: white"> Payment Processing... </p>
</ngx-spinner>

<div class="form-out">

    <h2 class="text-center pt-2" style="color: #fff;text-shadow: 2px 2px black;">DREAMWORKS REGISTRATION</h2>



    <div class="col text-center" style="z-index:2;">


        <div class="container p-center">
            <ul class="progressbar">
                <li class="actived">Sign Up & Select course </li>
                <li class="active">Complete Payment</li>
                <li>Start Journey with Dreamworks </li>

            </ul>

        </div>
    </div>



</div>

<div class="container text-center">
    <h3 class="my-4">Verify & Complete Payment </h3>
</div>

<div class="container mt-5 mb-5">

    <div class="container userdatas">

        <div class="row">
            <div class="col-4">Name </div>

            <div class="col-6 u-d ">{{user?.name}}</div>
        </div>
        <div class="row">
            <div class="col-4">Email</div>

            <div class="col-6 u-d">{{user?.email}}</div>
        </div>
        <div class="row">
            <div class="col-4 ">Mobile number</div>

            <div class="col-6 u-d">{{user?.phone}}</div>
        </div>
        <div class="row">
            <div class="col-4 ">Education Qualfication</div>

            <div class="col-6 u-d">{{user?.qualification}}</div>
        </div>
        <div class="row">
            <div class="col-4">Course Selected</div>

            <div class="col-6 u-d">{{user?.course}}</div>
        </div>





        <div class="row">
            <div class="col-5">Course Fee</div>

            <div class="col-5 u-d">₹{{user?.coursefee}}</div>
        </div>
        <div class="row">
            <div class="col-5">SGST</div>

            <div class="col-5 u-d">₹{{user?.gst/2}}</div>
        </div>
        <div class="row">
            <div class="col-5">CGST</div>

            <div class="col-5 u-d">₹{{user?.gst/2}}</div>
        </div>
        <hr>
        <div class="row mt-3">
            <div class="col-5">Total Amount</div>

            <div class="col-5 u-d" style="color: black;">₹{{user?.amount}}</div>
        </div>

        <button class="btn  enroll-btn" (click)="createRzpayOrder(100)">
            Verify and Proceed to Pay <fa-icon [icon]="faArrowAltCircleRight"></fa-icon>

        </button>


    </div>




</div>