import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  
  constructor(private data:DataService,private router:Router) { }
  user= this.data.userdata;
  txndata:any;
  ngOnInit(): void {
this.txndata = this.data.transactionData;
if(this.data.flow == false)
{
  this.router.navigateByUrl('/');
}
  }



  // -created
  // -authorized
  // -captured
  // -refunded
  // -failed


}
