import { Component, OnInit } from '@angular/core';
import { WindowRefService } from '../window-ref.service';
import { ApiserviceService } from '../apiservice.service';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { faCheckCircle,faTimesCircle,faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from "ngx-spinner";
import { NgZone } from '@angular/core';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  providers: [WindowRefService]
})
export class PaymentsComponent implements OnInit {

  faArrowAltCircleRight = faArrowAltCircleRight;

  user:any;
  constructor(private winRef: WindowRefService, private api:ApiserviceService,
    public router:Router,private data:DataService,private spinner: NgxSpinnerService,
    private ngZone:NgZone
    ) {}

  ngOnInit() {
    
this.user = this.data.userdata;
// console.log("recievd data", this.user);

if(this.data.flow == false)
{
  this.router.navigateByUrl('/');
}

  }


  gotoSuccess(){
    this.router.navigateByUrl('/success');
  }

  createRzpayOrder(data) {
    // console.log(data);
    this.spinner.show();
    let temp =   
  {
    "name":this.user?.name,
    "phone":this.user?.phone,
    "amount":this.user?.amount,
    "email": this.user?.email,
    "gst": this.user?.gst,
    "coursefee":this.user?.coursefee,
    "qualification": this.user?.qualification,
    "course": this.user?.courseId

    
  };
  // console.log("my temp",temp);
  
    this.api.register(temp).subscribe((dat)=>{
      // console.log("response data : ",dat);
      // console.table(dat);
      
      this.payWithRazor(dat['id']);
      
     });
    // call api to create order_id
    // this.payWithRazor(order_id);
  }

  payWithRazor(val) {
    // let amt = this.user.amount * 100;
    const options: any = {
      key: 'rzp_live_djWP7eRZeo67Z9',
      amount: this.user.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'Dreamworks Aviation', // company name or product name
      description: 'Course fee',  // product description
      image: 'assets/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      prefill: {
        name:this.user.name,
        email: this.user.email,
        contact: this.user.phone,
    },
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#edcd1f'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      // console.log(response);
        let dat ={
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature:response.razorpay_signature,
              amount: options.amount
        }
        this.api.verify(dat).subscribe((res)=>
        {
        // console.log("last response:",res);
        this.data.transactionData = res;
        this.spinner.hide();
        this.ngZone.run(()=> this.router.navigateByUrl('/success'));
        
        });
      // console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      this.ngZone.run(()=> this.spinner.hide());
      // console.log('Transaction cancelled.');
      
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();

  }

}