import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { faCheckCircle, faTimesCircle, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from "ngx-spinner";
import { ApiserviceService } from '../apiservice.service';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  regForm: FormGroup;
  courseNot: boolean = false;
  price: any = '0';
  gst: number = 0;
  package: any = 'hh';
  transaction_status: any = 'yes';
  payed: boolean = false;
  selectedCourse: any = '';
  courseFee: number = 0;
  courseId: number;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faArrowAltCircleRight = faArrowAltCircleRight;
  customeFee: boolean = false;
  // courses = [{'course':'Registration','fee':'5000'},
  //           {'course':'RTR','fee':'15000'},
  //           {'course':'TEST','fee':'1'}
  //         ];
  courses: any = [];

  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService,
    private api: ApiserviceService, private router: Router, private data: DataService
    , private toastr: ToastrService) { }

  ngOnInit(): void {
    this.regForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      course: ['', Validators.required],
      qualification: ['', Validators.required],
      fee: ['', Validators.required]

    });
    // this.initConfig();
    this.loadCourse();


  }


  loadCourse() {
    this.api.courses().subscribe(
      (data) => {
        // console.table(data);
        this.courses = data;
        this.courseNot = true;
      },
      (err) => {
        console.log(err, "this is error");
        this.toastr.warning("check your internet connection!! & Reload", "Courses Not Loaded!", {
          timeOut: 3000
        });

      }
    )
  }

  register(dat) {
    if (this.customeFee) {
      this.courseFee = dat['fee'];
      this.gst = this.courseFee * 18 / 100;
      let total: any = this.courseFee + this.gst;
      this.price = total;
    }

    let temp =
    {
      "email": dat['email'],
      "name": dat['name'],
      "phone": dat['mobile'],
      "amount": this.price,
      "gst": this.gst,
      "coursefee": this.courseFee,
      "qualification": dat['qualification'],
      "course": dat['course'],
      "courseId": this.courseId

    };

    this.data.userdata = temp;

    // this.api.register(temp).subscribe((dat)=>{
    //  console.log("response data : ",dat);
    // });
    // console.log(dat);
    this.data.flow = true;
    this.gotoPayment();
  }
  gotoPayment() {
    this.router.navigateByUrl('/payment');
  }
  courseSelected(data) {
    //  console.log(data);
    this.selectedCourse = data;
    let cc = this.courses.filter((ele) =>
      ele.name == data
    );
    if (cc[0]['customizable']) {
      this.regForm.patchValue({fee:''});
      this.customeFee = true;
    }
    else {
      this.regForm.patchValue({fee:'xxx'});
      this.customeFee = false;
      this.courseFee = parseInt(cc[0]['fee']);
      this.gst = this.courseFee * 18 / 100;
      let total: any = this.courseFee + this.gst;

      this.price = total;
    }

    this.courseId = cc[0]['id'];

    //  console.log("gst",this.gst); 
    //  console.log("gst",this.price); 

  }




}





