<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size = "medium" color = "#fad418" type = "ball-atom" [fullScreen] = "true"><p style="color: white" > Payment Processing... </p></ngx-spinner>

<div class="form-out  ">

    <h2 class="text-center pt-2" style="color: #fff;text-shadow: 2px 2px black;">DREAMWORKS REGISTRATION</h2>
   
  
  
        <div class="col text-center" style="z-index: 2;">
  
  
          <div class="container p-center">
            <ul class="progressbar">
                <li class="active">Sign Up & Select course </li>
                <li >Complete Payment</li>
                <li>Start Journey with Dreamworks </li>
               
            </ul>
            
          </div>
        </div>
  
    
    
  </div>
  
<br><br>

<div class="container text-center">
    <h3 class="my-4">Sign Up & Select course </h3>
</div>


<div class="container form-cs">
    <form class="p-2" [formGroup]="regForm" (ngSubmit)="register(regForm.value)">

        <fieldset>
            <legend>Fill Details:</legend>

        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="product" class="label">Full name</label>
                    
                    <input class="form-control" type="text" fieldSize="small" nbInput fullWidth id="name"
                         formControlName="name">
                    <div
                        *ngIf="regForm.get('name').invalid && (regForm.get('name').dirty || regForm.get('name').touched)">
                        <div *ngIf="regForm.get('name').errors.required" class="error-text">
                            *Name is required.
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="row">

            <div class="col">
                <div class="form-group">
                    <label for="email" class="label">Email</label>
                    <input  class="form-control" type="email" fieldSize="small" nbInput fullWidth   
                        formControlName="email">
                    <div
                        *ngIf="regForm.get('email').invalid && (regForm.get('email').dirty || regForm.get('email').touched)">
                        <div *ngIf="regForm.get('email').errors.required" class="error-text">
                            *email is required.
                        </div>
                    </div>
                </div>
              </div>
         </div>


         <div class="row">

            <div class="col">
                <div class="form-group">
                    <label for="email" class="label">Mobile Number</label>
                    <input  class="form-control" type="number" fieldSize="small" nbInput fullWidth   
                        formControlName="mobile">
                    <div
                        *ngIf="regForm.get('mobile').invalid && (regForm.get('mobile').dirty || regForm.get('mobile').touched)">
                        <div *ngIf="regForm.get('mobile').errors.required" class="error-text">
                            *Mobile  is required.
                        </div>
                    </div>
                </div>
              </div>
         </div>
         <div class="row">

            <div class="col">
                <div class="form-group">
                    <label for="email" class="label">Education Status</label>
                 
                        <select class="form-control" formControlName="qualification" >
                            <option value=''>Select Qualification</option>
                            <option *ngFor="let item of ['10+2','Undergraduate','Postgraduate']" [value]="item">{{item}}</option>
                            
                          </select>
                    <div
                        *ngIf="regForm.get('qualification').invalid && (regForm.get('qualification').dirty || regForm.get('qualification').touched)">
                        <div *ngIf="regForm.get('qualification').errors.required" class="error-text">
                            *Qualification is required.
                        </div>
                    </div>
                </div>
              </div>
         </div>



         <div class="row">

            <div class="col">
                <div class="form-group">
                    <label for="email" class="label">Course</label>
                    <!-- <input  class="form-control" type="text" fieldSize="small" nbInput fullWidth   placeholder="Course"
                        formControlName="course"> -->

                        <select class="form-control" formControlName="course" (change)="courseSelected(regForm.get('course').value)">
                           <option *ngIf="!courseNot" value=''>Course not loaded! Please reload!! </option>
                            <option *ngIf="courseNot" value=''>Select Course</option>
                            <option *ngFor="let item of courses" [value]="item.name">{{item.name}}</option>
                            
                          </select>


                    <div
                        *ngIf="regForm.get('course').invalid && (regForm.get('course').dirty || regForm.get('course').touched)">
                        <div *ngIf="regForm.get('course').errors.required" class="error-text">
                            *Course is required.
                        </div>
                    </div>
                </div>
              </div>
         </div>


        
         <div class="row" *ngIf="customeFee">
            <div class="col">
                <div class="form-group">
                    <label for="product" class="label">Enter amount(₹) paying</label>
                    
                    <input class="form-control" type="number" placeholder="₹" fieldSize="small" nbInput fullWidth id="fee"
                         formControlName="fee">
                    <div
                        *ngIf="regForm.get('fee').invalid && (regForm.get('fee').dirty || regForm.get('fee').touched)">
                        <div *ngIf="regForm.get('fee').errors.required" class="error-text">
                            *fee is required.
                        </div>
                    </div>
                </div>
            </div>

        </div>


         <button   type="submit" [disabled]='!regForm.valid' class="btn  enroll-btn">
            Proceed  <fa-icon [icon]="faArrowAltCircleRight" ></fa-icon>
        </button>
        </fieldset>
         
        <!-- <fieldset *ngIf="regForm.valid">   
            <legend>Payment Details: </legend>

            
            <span>Course Selected: {{selectedCourse}}</span><br>
            <span>Fees: ₹{{courseFee}}</span><br>
            <span>Gst: ₹{{gst}}</span><br>
            <span style="font-weight: 600;">Total: ₹ {{price}}</span><br>

                
        </fieldset> -->
        <!-- <div class="conatiner check">
            <span  class="text-center">Complete form details </span> 
            <fa-icon [icon]="faCheckCircle" *ngIf="regForm.valid" class="green"></fa-icon> <fa-icon  class="red" [icon]="faTimesCircle" *ngIf="!regForm.valid"></fa-icon>
            <br>
            <span  class="text-center">Complete payment </span>
            <fa-icon *ngIf="payed" [icon]="faCheckCircle"  class="green"></fa-icon> <fa-icon  class="red" *ngIf="!payed" [icon]="faTimesCircle" ></fa-icon>
            
        </div> -->
       
    </form>




</div>
