import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {

  constructor(private http:HttpClient) { }


  register(data){
    return this.http.post("https://api.dreamworksaviation.com/v1/course/signup",data);
  }
  verify(data){
    return this.http.post("https://api.dreamworksaviation.com/v1/course/payment/verify",data);
  }

  courses(){
    return this.http.get("https://api.dreamworksaviation.com/v1/course");
  }
  


}
