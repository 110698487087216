<div class="form-out  ">

    <h2 class="text-center pt-2" style="color: #fff;text-shadow: 2px 2px black;">DREAMWORKS REGISTRATION</h2>
  
  
  
        <div class="col text-center" style="z-index: 2;">
  
  
          <div class="container p-center">
            <ul class="progressbar">
                <li class="actived">Sign Up & Select course </li>
                <li class="actived" >Complete Payment</li>
                <li class="actived">Start Journey with Dreamworks </li>
               
            </ul>
            
          </div>
        </div>
  
    
  </div>

  <div class="container text-center my-5">
    <p>
      Thank you!<br> {{user?.name}}
Registration is completed successfully.<br>
Your account details and payment receipt have been sent to the email. <span style="color: blue;"> {{txndata?.email}}</span>
    </p>

    <br>

    <span><a href="https://dreamworksaviation.com">Return back to home</a></span>

    <br>
   

  </div>