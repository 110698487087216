<div class="container-fluid" >
      <div class="row">
            <div class="yellow col-lg-4 col-md-4 col-xs-12">

              <img id="a-logo" src="assets/bgcolorlogo.gif" alt="Logo img" class="a-logo">

            </div>
            <div class="blue col-lg-8 col-md-8 col-xs-12">
              <span class="float-right p-2 back" onclick="window.location.href='https://www.dreamworksaviation.com'"> Back to home</span>
             
            </div>
      </div>

</div>



