<div class="container-fluid p-5 bg-c">
<div class="row">

<div class="col">
<img src="assets/bgcolorlogo.gif" alt="dreamworks logo" width="300px" height="auto">

<p>Dream Works Courses gives you the foundation upon which you build your future in Aviation</p>


</div>

<div class="col">
<div class="row">
    <div class="col list">
        <li><a href="https://dreamworksaviation.com">Home</a></li>
        <li><a href="https://dreamworksaviation.com#services">Services</a></li>
        <li><a href="https://dreamworksaviation.com#about">About</a></li>
        <li><a href="https://dreamworksaviation.com/#contact">Contact</a></li>
            <li><a href="https://dreamworksaviation.com/terms.html">Terms of Use</a></li>
            <li><a href="https://dreamworksaviation.com/privacy.html">Privacy</a></li>
            <li><a href="https://dreamworksaviation.com/refund.html">Refund</a></li>
    </div>
    <div class="col">
        <b style="color: #3d5975;">Get In Touch</b><br>
        <span><i>info@dreamworksaviation.com</i></span><br>



        <div class="social-links">
            <a href="#" class="twitter" style="padding-right: 10px;"><i class="fa fa-twitter" aria-hidden="true" style="color: #3d5975;"></i></a>
            <a href="#" class="facebook" style="padding-right: 10px;"><i class="fa fa-facebook" style="color: #3d5975;"></i></a>
            <a href="https://instagram.com/dreamworksaviation?igshid=1um5dskbph1ag" class="instagram" style="padding-right: 10px;"><i class="fa fa-instagram" style="color: #3d5975;"></i></a>
            <a href="#" class="google-plus" style="padding-right: 10px;"><i class="fa fa-skype" style="color: #3d5975;"></i></a>
            <a href="#" class="linkedin" style="padding-right: 10px;"><i class="fa fa-linkedin" style="color: #3d5975;"></i></a>
            </div>


    </div>
    
</div>

</div>

    
</div>
</div>


<div class="foot-out">
    Copyright © 2020-2021 Dreamworks Aviation  | Developed by <a style="color: white;" href="www.inglecorp.co.in">Ingle Corp</a>
</div>